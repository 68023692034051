import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import ResetPassword from 'components/reset-password/ResetPassword';
import Reinsurance from 'components/home-group/reinsurance/Reinsurance';

const ResetPasswordPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Réinitialiser le mot de passe" />
    <ResetPassword />
    <Reinsurance location={props.location} />
  </Layout>
);

export default ResetPasswordPage;
