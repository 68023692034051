import React from 'react';

import ResetPasswordForm from './ResetPasswordForm';

import './resetPassword.scss';

const ResetPassword = (props) => (
  <div>
    <div className="login-page--header">
      <h1>Entrez dans la bulle Calicéo</h1>
    </div>
    <ResetPasswordForm location={props.location} />
  </div>
);

export default ResetPassword;
