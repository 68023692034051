import React from 'react';
import { navigate } from 'gatsby';
import qs from 'qs';
import { Form, Input, notification } from 'antd';
import { APIWOI } from 'api';

const ResetPasswordForm = (props) => {
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    APIWOI.get(`/zrbttxpsesfdpwfs?mail=${values.email}`).then((res) => {
      if (res.data.status === 1) {
        notification.success({
          message: 'Succès! Vous recevrez un e-mail avec des instructions supplémentaires.',
        });
      } else {
        notification.error({
          message: 'Une erreur s\'est produite. Veuillez réessayer',
        });
      }
    }).catch((e) => {
      notification.error({
        message: 'Une erreur s\'est produite. Veuillez réessayer',
      });
    });
  };

  return (
    <div className="login-form--section">
      <div className="login-form--wrapper">
        <div className="login-form">
          <h3 className="login-form--title">Réinitialisez votre mot de passe</h3>
          <Form layout="vertical" form={form} name="control-hooks" onFinish={onSubmit}>
            <Form.Item
              name="email"
              label="Votre email"
              validateTrigger="onSubmit"
              rules={
                [
                  { required: true, message: 'Ce champ est requis' },
                  { type: 'email', message: 'L\'entrée n\'est pas un e-mail valide' },
                ]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <div className="change-pass-buttons--wrapper">
                <button type="submit" className="primary-caliceo--button">
                  Soumettre
                </button>
                <button type="button" onClick={() => navigate('/')} className="secondary-caliceo--button">
                  Retour au site
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
